import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { InfoLinkComponent } from './info-link/info-link/info-link.component';
import { MerchantDetailComponent } from './merchant-detail/merchant-detail.component';
import { MerchantComponent } from './merchant/merchant.component';
import { StoryViewComponent } from './story-view/story-view.component';
import { UserProfileComponent } from './user-profile/user-profile/user-profile.component';
import { VoucherDetailComponent } from './voucher-detail/voucher-detail.component';
import { VoucherComponent } from './voucher/voucher.component';


const routes: Routes = [
  {path:'', redirectTo: 'home', pathMatch:'full'},
  {path:'home',component:HomeComponent},
  {path:'merchant',component:MerchantComponent},
  {path:'merchant/detail/:id',component:MerchantDetailComponent},
  {path:'voucher',component:VoucherComponent},
  {path:'voucher/detail/:id',component:VoucherDetailComponent},
  {path:'info/:id',component:InfoLinkComponent},
  {path:'story/:id',component:StoryViewComponent},
  {path:'story/:id/:uid',component:StoryViewComponent},
  {path:'user/info/:id',component:UserProfileComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
