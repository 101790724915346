<div class="body">
    <div class="main-div">
        <div class="logoPic" style="background-image:url({{selectedbrand?.logo}});" ></div>
        <p class="brandName">{{selectedbrand?.name}}</p>
            
        <button *ngIf="facebook" class="button" nbButton fullWidth shape="semi-round" outline status="info" (click)="goToFaceook()"><nb-icon icon="facebook-outline"></nb-icon>Facebook</button>
        <button *ngIf="instagram" class="button" nbButton fullWidth shape="semi-round" outline status="danger"(click)="goToInstagram()"><nb-icon icon="camera-outline"></nb-icon>Instagram</button>
        <button *ngIf="website" class="button" nbButton fullWidth shape="semi-round" outline status="primary" (click)="goTowebsite()" ><nb-icon icon="globe-outline"></nb-icon>Website</button>
        <button *ngIf="phone" class="button" nbButton fullWidth shape="semi-round" outline status="success" (click)="goTowhatapp()"><nb-icon icon="phone-call-outline"></nb-icon>Whatsapp</button>
        <button *ngIf="bookingSite" class="button" nbButton fullWidth shape="semi-round" outline status="warning" (click)="goToBooking()"><nb-icon icon="calendar-outline"></nb-icon>Book an appointment</button>

        <div class="buttonGroup">
            <button class="button" nbButton fullWidth shape="semi-round" outline status="danger"(click)="sendtoAndroid()"><nb-icon icon="star"></nb-icon>Android</button>
            <button class="button" nbButton fullWidth shape="semi-round" outline status="info" (click)="sendtoiOS()"><nb-icon icon="star"></nb-icon>iOS</button>
        </div>

    </div>
    
</div>
