import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MerchantService } from '../merchant/merchant.service';
import { UserService } from '../user-profile/user.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-story-view',
  templateUrl: './story-view.component.html',
  styleUrls: ['./story-view.component.scss'],
 
})
export class StoryViewComponent implements OnInit {

  

  id:string;
  uid:string;
  storyObject;
  urlObject;
  countPrev:number = 0;
  userCounter:number = 0;
  currentCount:number = 0;
  likeCount:number = 0;
  linkSpace:boolean = false;
  audio = true;
  audioTitle = 'Unmute';
  audioIcon = 'volume-up-outline';
  userName = 'User';
  sharedUser = false;
  userObject;
  deviceInfo = null;
  buttonState:boolean = true;
  constructor(private meta:Meta, private merchant:MerchantService,private deviceService:DeviceDetectorService ,private user:UserService ,private route:ActivatedRoute,private http:HttpClient) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.uid = this.route.snapshot.paramMap.get('uid');
    this.merchant.getStoryById(this.id).subscribe(story => { 
      this.storyObject = story.payload[0];
      this.urlObject = this.storyObject.url.en;
      this.userName = this.storyObject.userName;
      this.meta.updateTag(
        { property: 'og:title', content: this.storyObject.url.en },
      );

      this.meta.updateTag(
        { property: 'og:image', content: this.storyObject.logo },
      );

      if(this.storyObject.brandId !== 'NIL'){
        this.linkSpace = true;
      }
      if(this.uid){
        this.user.getUserbyId(this.uid).subscribe(user =>{
          this.userObject = user.payload[0];
          if(this.userObject !== undefined){
            this.sharedUser = true;
            this.deviceInfo = this.deviceService.getDeviceInfo();
            const isMobile = this.deviceService.isMobile();
            const isTablet = this.deviceService.isTablet();
            const isDesktopDevice = this.deviceService.isDesktop();
            
            if(!isDesktopDevice){
              if(isMobile || isTablet){
               this.buttonState = false;
              }
            }
           
          }
        })
      }
      this.likeCount = this.storyObject.likeCount;
    });
    setInterval(()=> this.sendToServer(),5000);
  }

 

  get totalCounter() { 
      return this.userCounter; 
  }




 async sendToServer(){
  const countNow = this.userCounter;
 

  if(this.userCounter !== 0 ){
   
    //Sending Data to server
    this.merchant.sendClap(this.id,countNow);

    //Reset Count
    this.userCounter = 0;
    
  }

  
}

async sendtoAppStore(){
  switch(this.deviceService.os){
    case 'iOS':  window.open("https://apps.apple.com/sg/app/rhine/id1573217632", "_blank");
    break;
    case 'Android': window.open("https://play.google.com/store/apps/details?id=com.rhinedigital.rhine", "_blank");
    break;
  }
}

async backToUser(){
  window.open(`${this.storyObject.userURL}`, "_blank");
}

async sendtoiOS(){
  window.open("https://apps.apple.com/sg/app/rhine/id1573217632", "_blank");
}

async sendtoAndroid(){
  window.open("https://play.google.com/store/apps/details?id=com.rhinedigital.rhine", "_blank");
}

async sendtoMap(){
  let url = `https://maps.google.com/?q=${this.storyObject.latitude},${this.storyObject.longitude}`;
  window.open(url,'_bank');
}

async toggleAudio(){
  this.audio = !this.audio;
  if(this.audio){
    this.audioTitle = 'Unmute';
    this.audioIcon = 'volume-up-outline';
  }
  else {
    this.audioTitle = 'Mute';
    this.audioIcon = 'volume-off-outline';
  }
}


}
