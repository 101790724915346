
    <p class="title">Merchants</p>

    <div class="merchant-container">
        <div *ngFor="let merchant of brands">
            <div class="merchant" routerLink="/merchant/detail/{{ merchant.id }}">
                <div class="merchantInnerContainer" >
                    <div class="merchantLogo" style = "background-image: url({{merchant.logo}});"></div>
                    <p class="merchantTitle">{{merchant.name}}</p>
                </div>
                <div class="merchantRating">
                    <p><nb-icon icon="star" status="info" [options]="{ animation: { type: 'zoom' } }"></nb-icon> {{(merchant)?.rating | number:'1.0-1'}}</p>
                </div>
                <img class="merchantImage" src="{{merchant.url}}" alt="">
            </div>
        </div>
    </div>
    