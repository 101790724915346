import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, shareReplay } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';





@Injectable({
  providedIn: 'root'
})
export class VoucherService {

 
  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  getVouchers(): Observable <any> {
    return this.http.get('https://us-central1-rhinedigital.cloudfunctions.net/getVoucherWF').pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(this.handleError('getVoucher', []))
    );
  }
 
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead


      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  
}
