import { Component, OnInit } from '@angular/core';
import { MerchantService } from './merchant.service';

@Component({
  selector: 'app-merchant',
  templateUrl: './merchant.component.html',
  styleUrls: ['./merchant.component.scss']
})

export class MerchantComponent implements OnInit {
  brands;
  constructor(private merchant:MerchantService) { }

  ngOnInit(): void {
    this.merchant.getBrands().subscribe(brands => { 
      this.brands = brands.payload;
      console.log(this.brands)
    
    });
    
  }



}
