import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { shareReplay, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  errorMessage: any;

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json', 
      'Access-Control-Allow-Origin': '*'
  })
  };

  getUserbyId(id:string): Observable <any> {
    return this.http.get(`https://us-central1-rhinedigital.cloudfunctions.net/getUserbyId?id=${id}`).pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(this.handleError('getBrands', []))
    );
  }


  sendIntent(uid:string,intent:string,brandName:string):void{
    let queryParams = new HttpParams();
    queryParams = queryParams.append("uid",uid);
    queryParams = queryParams.append("intent",intent);
    queryParams = queryParams.append("brandName",brandName);
    //Sending Intent
    console.log('Send Intent');
    this.http.get(`https://us-central1-rhinedigital.cloudfunctions.net/intentAnalytics`,{params:queryParams}).subscribe();
  
  }


   
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead


      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
