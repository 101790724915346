<nb-layout>
<!-- 
  <nb-layout-header fixed>
  

  
  </nb-layout-header > -->

  <!-- <nb-sidebar>
   
      <div class="navBar">
        <a routerLink="/home" class="logo"><img src="../assets/icon/RhineFullFont.png" width="90%"></a>
        <nav>
          <ul>
            <li><a routerLink="/home"><nb-icon icon="home"></nb-icon> Home</a></li>
            <li><a routerLink="/merchant"><nb-icon icon="people"></nb-icon> Merchants</a></li>
            <li><a routerLink="/voucher"><nb-icon icon="credit-card"></nb-icon> Vouchers</a></li>
            <li><a href="https://rhine.digital/legal/privacy"><nb-icon icon="book-open"></nb-icon>Privacy Policy</a></li>
            <li><a href="https://rhine.digital/legal/terms-of-services"><nb-icon icon="book"></nb-icon>Term of Service</a></li>
          </ul>
        </nav>
        <p class='allrights'>All rights reserved 2021</p>
      </div>
    
  </nb-sidebar> -->

  <nb-layout-column>
  
    
    <div class="container">
      <router-outlet></router-outlet>
    </div>
    
   
  </nb-layout-column>

  

</nb-layout>
