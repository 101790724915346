import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MerchantService } from '../merchant/merchant.service';
import { VoucherService } from '../voucher/voucher.service';


@Component({
  selector: 'app-voucher-detail',
  templateUrl: './voucher-detail.component.html',
  styleUrls: ['./voucher-detail.component.scss']
})
export class VoucherDetailComponent implements OnInit {
  selectedVoucher;
  id;
  currentURL;
  sector;
  merchant;
  brandId;
  
  constructor(private voucher:VoucherService,private route: ActivatedRoute, private brand:MerchantService ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    
    this.voucher.getVouchers().subscribe(vouchers => { 
      const tempid = this.id;
      const temp = vouchers.payload;
      let tempVoucher;
      var filterObj = temp.filter(function(e) {
        if(e.id === tempid)
        tempVoucher = e;
      });
     
      this.selectedVoucher = tempVoucher;
      this.brandId = this.selectedVoucher.brandId;
      this.currentURL = window.location.href;

    })

    this.brand.getBrands().subscribe(brands =>{
      const tempid = this.brandId;
      const temp = brands.payload;
      let tempbrand;

      let filterObj = temp.filter(function(e) {
        if(e.id === tempid)
        tempbrand = e;
      });

      this.merchant = tempbrand;
      this.sector = tempbrand.sector;

    })
   

  }

 gotoURL(url) {
  window.open("https://"+url, "_blank");
  }

}
