import { Component, OnInit } from '@angular/core';
import { VoucherService } from './voucher.service';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss']
})
export class VoucherComponent implements OnInit {
vouchers=[];
  constructor(private voucher:VoucherService) { }

  ngOnInit(): void {
    this.voucher.getVouchers().subscribe(vouchers => { 
      this.vouchers = vouchers.payload;
      console.log(this.vouchers)
    })
   

  }
  
}
