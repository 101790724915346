<div class="main-div">

    <div class="logoPic" style="background-image:url({{storyObject?.logo}});" ></div>

    <p class="brandName">{{storyObject?.name}}</p>

    <div class="header-bg">
       <div class="banner" *ngIf="sharedUser">
        <div class="userlogoPic" style="background-image:url({{userObject?.logo}});" ></div>
        <div class="userDetail">
            <div class="userText">{{userObject?.name}}</div>
            <div class="userText">Join me on Rhine</div>
        </div>
       
        <button class="bannerButton" nbButton shape="semi-round" status="danger" [disabled]="buttonState" (click)="sendtoAppStore()">Join</button>
       </div>
        <video class="header-inner" src={{urlObject}} playsinline
        loop
        autoplay
        [muted]="audio"> </video>

    </div>

    <div>
        <button class="button" nbButton shape="semi-round" outline status="success" (click)="toggleAudio()"><nb-icon icon="{{audioIcon}}"></nb-icon>{{audioTitle}}</button>
    </div>
    
  
    
    <div class="buttonGroup">

        <app-clap-button class="button"
        [totalCounter]="totalCounter" 
        [userCounter]="userCounter" 
        (userCounterChange)="userCounter = userCounter + 1"
        >
      </app-clap-button>
       
    </div>

    <div>
        <button  class="button" nbButton fullWidth shape="semi-round" outline status="warning" (click)="backToUser()"><nb-icon icon="at-outline"></nb-icon>{{userName}}</button>
        
    </div>
   

    <div class="buttonGroup">
        <button *ngIf="linkSpace" class="button" nbButton fullWidth shape="semi-round" outline status="success" routerLink="/info/{{storyObject?.brandId}}"><nb-icon icon="link-2-outline"></nb-icon>Link Space</button>
        <button  class="button" nbButton fullWidth shape="semi-round" outline status="primary" (click)="sendtoMap()"><nb-icon icon="map-outline"></nb-icon>Map</button>
    </div>

   

    <div class="buttonGroup">
        <button class="button" nbButton fullWidth shape="semi-round" outline status="danger"(click)="sendtoAndroid()"><nb-icon icon="star"></nb-icon>Android</button>
        <button class="button" nbButton fullWidth shape="semi-round" outline status="info" (click)="sendtoiOS()"><nb-icon icon="star"></nb-icon>iOS</button>
    </div>

   
    
</div>
