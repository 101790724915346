import { Component, OnInit } from '@angular/core';
import { MerchantService } from '../../merchant/merchant.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-info-link',
  templateUrl: './info-link.component.html',
  styleUrls: ['./info-link.component.scss']
})
export class InfoLinkComponent implements OnInit {

  selectedMerchant;
  id;
  brands;
  selectedbrand;
  facebook:boolean = false;
  instagram:boolean = false;
  phone:boolean = false;
  website:boolean = false;
  bookingSite:boolean = false;
  constructor(private merchant: MerchantService,private route: ActivatedRoute,private http:HttpClient) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.merchant.getBrandbyId(this.id).subscribe(brands => { 
      this.brands = brands.payload;
      const tempid = this.id;
      let tempBrand;
      var filterObj = this.brands.filter(function(e) {
        if(e.id === tempid)
        tempBrand = e;
      });

      this.selectedbrand = tempBrand;
    

        if(this.selectedbrand.facebook !== 'NIL'){
          this.facebook = true;
         
        }

        if(this.selectedbrand.instagram !== 'NIL'){
          
          this.instagram = true;
        }

        if(this.selectedbrand.phone !== 'NIL'){

          this.phone = true;
        }

        if(this.selectedbrand.webURL !== 'NIL'){
          this.website = true;
        }

        if(this.selectedbrand.bookingSite !== 'NIL'){
          this.bookingSite = true;
        }

       // console.log(this.selectedbrand);
    });
  }

   goTowebsite(){
    this.merchant.sendIntent(this.id,'Website',this.selectedbrand?.name);
    window.open(this.selectedbrand?.webURL, "_blank");
  }

  goToBooking(){
    this.merchant.sendIntent(this.id,'Booking',this.selectedbrand?.name);
    window.open(this.selectedbrand?.bookingSite, "_blank");
  }

   goToFaceook(){
    this.merchant.sendIntent(this.id,'Facebook',this.selectedbrand?.name);
    window.open(this.selectedbrand?.facebook, "_blank");
  }

   goToInstagram(){
     this.merchant.sendIntent(this.id,'Instagram',this.selectedbrand?.name);
    window.open(this.selectedbrand?.instagram, "_blank");
  }


   goTowhatapp(){
   this.merchant.sendIntent(this.id,'Phone',this.selectedbrand?.name); 
   let tempPhone = this.selectedbrand?.phone;
   tempPhone.replaceAll(/\s/g,'');

   let brandName = this.selectedbrand?.name.replaceAll(/&/g, '%26');
   brandName.replaceAll(/%/g, '%25');
   brandName.replaceAll(/$/g, '%24');
   brandName.replaceAll(/#/g, '%23');
   brandName.replaceAll(/'/g, '%27');
   brandName.replaceAll(/"/g, '%22');
   brandName.replaceAll(/!/g, '%21');

   const text = `Hi ${brandName} representative. I came from the Rhine App and would like to inquire more about your business offerings!`;

   window.open(`https://wa.me/${tempPhone}?text=${text}`, "_blank");
  }

  gotowhatapptest(){
    let tempPhone = this.selectedbrand?.phone;
    tempPhone.replaceAll(/\s/g,'');

    let brandName = this.selectedbrand?.name.replaceAll(/&/g, '%26');

    const text = `Hi ${brandName} representative. I came from the Rhine App and would like to inquire more about your business offerings!`;

    window.open(`https://wa.me/${tempPhone}?text=${text}`, "_blank");
  }

  async sendtoiOS(){
    window.open("https://apps.apple.com/sg/app/rhine/id1573217632", "_blank");
  }
  
  async sendtoAndroid(){
    window.open("https://play.google.com/store/apps/details?id=com.rhinedigital.rhine", "_blank");
  }



}

