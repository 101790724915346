import { Component, OnInit } from '@angular/core';
import { NbSearchService } from '@nebular/theme';
import { MerchantService } from '../merchant/merchant.service';
import { VoucherService } from '../voucher/voucher.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  value = '';
  vouchers=[];
  merchants=[];
  constructor(private searchService: NbSearchService,private voucher:VoucherService,private merchant:MerchantService) { 
    this.searchService.onSearchSubmit()
    .subscribe((data: any) => {
      this.value = data.term;
    })
   }

  ngOnInit(): void {
    this.voucher.getVouchers().subscribe(vouchers => {
      let temp; 
      temp = vouchers.payload;
      temp = temp.sort(() => Math.random() - 0.5);
  
      for (let i=0; i<4; i++){
        this.vouchers.push(temp[i]);
      } 
     
    })

    this.merchant.getBrands().subscribe(merchants =>{
      let temp; 
      temp = merchants.payload;
      temp = temp.sort(() => Math.random() - 0.5);
     
      for (let i=0; i<6; i++){
        this.merchants.push(temp[i]);
      } 
    })

  }

}
