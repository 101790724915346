import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, shareReplay } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';





@Injectable({
  providedIn: 'root',
})


export class MerchantService {
  errorMessage: any;

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json', 
      'Access-Control-Allow-Origin': '*'
  })
  };

  getBrand(): Observable<any> {
    return 
  }

  getBrands(): Observable <any> {
    return this.http.get('https://us-central1-rhinedigital.cloudfunctions.net/getBrandWF').pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(this.handleError('getBrands', []))
    );
  }

  getBrandbyId(id:string): Observable <any> {
    return this.http.get(`https://us-central1-rhinedigital.cloudfunctions.net/getBrandbyId?id=${id}`).pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(this.handleError('getBrands', []))
    );
  }

  getStoryById(id:string): Observable <any> {
    return this.http.get(`https://us-central1-rhinedigital.cloudfunctions.net/getStorybyId?id=${id}`).pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(this.handleError('getBrands', []))
    );
  }




  sendClap(storyId:string,clapCount:number):void{
    let queryParams = new HttpParams();
    queryParams = queryParams.append("storyId",storyId);
    queryParams = queryParams.append("clapCount",clapCount.toString());
 
    //Sending Clap
    console.log('Sending Clap');
    this.http.get('https://asia-east2-rhinedigital.cloudfunctions.net/contentLike', {params:queryParams}).subscribe();
  }


   sendIntent(brandId:string,intent:string,brandName:string):void{
    let queryParams = new HttpParams();
    queryParams = queryParams.append("brandId",brandId);
    queryParams = queryParams.append("intent",intent);
    queryParams = queryParams.append("brandName",brandName);
    //Sending Intent
    console.log('Send Intent');
    this.http.get(`https://us-central1-rhinedigital.cloudfunctions.net/intentAnalytics`,{params:queryParams}).subscribe();
  
  }


 
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead


      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  

}

interface Clap {
  storyId:string,
  clapCount:number,

}

interface Intent {
  brandId: string,
  intent: string,
  brandName: string
}