<nb-search type="rotate-layout"></nb-search>
<p class="title">Home</p>

<div class="fvoucher">
    <p class="subtitle">Featured Vouchers</p>
    <div class="menu-container">
        <div *ngFor="let voucher of vouchers">
           
                <div class="voucherContainer2" routerLink="/voucher/detail/{{ voucher.id }}">
                    <div class="voucherInnerContainer">
                        <div class="voucherLogo" style = "background-image: url({{voucher.brandLogo}});"></div>
                        <div class="voucherInnterContainer2">
                            <p class="voucherTitle">{{voucher.title}}</p>
                            <p class="voucherBrand">{{voucher.brandName}}</p>
                        </div>
                    </div>

                </div>
            
        
        </div>
    </div>
</div>



<div class="fmerchant">
    <p class="subtitle">Featured Merchants</p>
    <div class="merchant-container">
        <div *ngFor="let merchant of merchants">
            <div class="merchant" routerLink="/merchant/detail/{{ merchant.id }}">
                <div class="merchantInnerContainer" >
                    <div class="merchantLogo" style = "background-image: url({{merchant.logo}});"></div>
                    <p class="merchantTitle">{{merchant.name}}</p>
                </div>
                <div class="merchantRating">
                    <p><nb-icon icon="star" status="info" [options]="{ animation: { type: 'zoom' } }"></nb-icon> {{(merchant)?.rating | number:'1.0-1'}}</p>
                </div>
                <img class="merchantImage" src="{{merchant.url}}" alt="">
            </div>
        </div>
    </div>
</div>