import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbLayoutModule, NbCardModule, NbActionsModule, NbTooltipModule, NbTabsetModule, NbSearchModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { RouterModule } from '@angular/router'; // we also need angular router for Nebular to function properly
import { NbSidebarModule, NbButtonModule } from '@nebular/theme';
import { MerchantComponent } from './merchant/merchant.component';
import { VoucherComponent } from './voucher/voucher.component';
import { HomeComponent } from './home/home.component';
import { VoucherDetailComponent } from './voucher-detail/voucher-detail.component';
import { MerchantDetailComponent } from './merchant-detail/merchant-detail.component';
import { NbIconModule } from '@nebular/theme';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareButtonsPopupModule } from 'ngx-sharebuttons/popup';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { InfoLinkComponent } from './info-link/info-link/info-link.component';
import { StoryViewComponent } from './story-view/story-view.component';
import { ClapButtonModule } from './clap-button/clap-button.module';
import { UserProfileComponent } from './user-profile/user-profile/user-profile.component'



@NgModule({
  declarations: [
    AppComponent,
    MerchantComponent,
    VoucherComponent,
    HomeComponent,
    VoucherDetailComponent,
    MerchantDetailComponent,
    InfoLinkComponent,
    StoryViewComponent,
    UserProfileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbAlertModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbEvaIconsModule,
    HttpClientModule,
    RouterModule,  
    NbSidebarModule.forRoot(), //if this is your app.module
    NbButtonModule,
    ShareButtonsModule,
    ShareButtonsPopupModule,
    ShareIconsModule,
    NbCardModule,
    NbActionsModule,
    NbTooltipModule,
    NbIconModule,
    NbTabsetModule,
    NbSearchModule,
    ClapButtonModule
  ],
  providers:[],
  bootstrap: [AppComponent]
})
export class AppModule { }
