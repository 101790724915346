import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  id;
  facebook:boolean = false;
  instagram:boolean = false;
  phone:boolean = false;
  website:boolean = false;
  userObject;
  parentObject;
  selectedUser;
  brand;
  constructor(private user: UserService,private route: ActivatedRoute,private http:HttpClient) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.user.getUserbyId(this.id).subscribe(user =>{
      this.userObject = user.payload;

      this.selectedUser = this.userObject[0];

      this.parentObject = this.selectedUser.parentObject;

      this.brand = this.parentObject.brands[0];

      if(this.selectedUser.facebook !== 'NIL'){
        this.facebook = true;
       
      }

      if(this.selectedUser.instagram !== 'NIL'){
        
        this.instagram = true;
      }

      if(this.selectedUser.phone !== 'NIL'){

        this.phone = true;
      }

      if(this.selectedUser.webURL !== 'NIL'){
        this.website = true;
      }

    })
  }



  goTowebsite(){
    this.user.sendIntent(this.id,'Website',this.brand.name);
    window.open(this.selectedUser?.webURL, "_blank");
  }

   goToFaceook(){
    this.user.sendIntent(this.id,'Facebook',this.brand.name);
    window.open(this.selectedUser?.facebook, "_blank");
  }

   goToInstagram(){
     this.user.sendIntent(this.id,'Instagram',this.brand.name);
    window.open(this.selectedUser?.instagram, "_blank");
  }


   goTowhatapp(){
   this.user.sendIntent(this.id,'Phone',this.brand.name); 
   let tempPhone = this.selectedUser?.phone;
   tempPhone.replaceAll(/\s/g,'');

   let brandName = this.selectedUser?.name.replaceAll(/&/g, '%26');
   brandName.replaceAll(/%/g, '%25');
   brandName.replaceAll(/$/g, '%24');
   brandName.replaceAll(/#/g, '%23');
   brandName.replaceAll(/'/g, '%27');
   brandName.replaceAll(/"/g, '%22');
   brandName.replaceAll(/!/g, '%21');

   const text = `Hi ${brandName} I came from the Rhine App and would like to inquire more!`;

   window.open(`https://wa.me/${tempPhone}?text=${text}`, "_blank");
  }

  async sendtoiOS(){
    window.open("https://apps.apple.com/sg/app/rhine/id1573217632", "_blank");
  }
  
  async sendtoAndroid(){
    window.open("https://play.google.com/store/apps/details?id=com.rhinedigital.rhine", "_blank");
  }

}
