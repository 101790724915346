<!-- V1
    <p class="title">Vouchers</p>


    <div class="menu-container">
        <div *ngFor="let voucher of vouchers" >
                        <div class="voucherContainer">
                    
                            <div class="colorLabel"></div>
                                <div class="voucherText">
                                    <p class="voucherTitle">{{voucher.title}}</p>
                                    <p class="voucherSubTitle">{{voucher.subtitle}}</p>
                                    <button nbTooltip="{{voucher.brandName}}" nbTooltipPlacement="bottom" nbTooltipStatus="danger" nbButton size='tiny' routerLink="/voucher/detail/{{ voucher.id }}" status="danger">More info</button>
                                </div>
                            <div  class="voucherImage" style = "background-image: url({{voucher.url}});">
                            
                        </div>
            
                </div>
                <p class="brandName">{{voucher.brandName}}</p>
         </div>
 
    </div> -->

<p class="title">Vouchers</p>

    <div class="menu-container">
        <div *ngFor="let voucher of vouchers">
           
                <div class="voucherContainer2" routerLink="/voucher/detail/{{ voucher.id }}">
                    <div class="voucherLogo2" style = "background-image: url({{voucher.brandLogo}});"></div>
                    <div class="line"></div>
                    <p class="voucherBrand">{{voucher.brandName}}</p>
                    <p class="voucherExpiry">Available till {{(voucher)?.endAt._seconds * 1000 | date:'dd-MM-yyyy'}}</p>
                    <img class="voucherImage2" src="{{voucher.url}}" alt="">
                    <div class="voucherBanner"><p class="voucherTitle2">{{voucher.title}}</p></div>
                </div>
            
        
        </div>
    </div>