<!-- 
<p class="title">{{(selectedVoucher)?.brandName}}</p>


<div class="voucherContainer">
    <div class="colorLabel"></div>
    <div class="voucherText">
        <p class="voucherTitle">{{(selectedVoucher)?.title}}</p>
        <p class="voucherSubTitle">{{(selectedVoucher)?.subtitle}}</p>
       
    </div>
    <div class="voucherImage" style = "background-image: url({{(selectedVoucher)?.url}});">
        
    </div>

</div>

<div class="voucherExpiry">
    <br>
    <p>Promo Code: {{(selectedVoucher)?.promoCode}}</p>
    <p>Expiry Date: {{(selectedVoucher)?.endAt._seconds * 1000 | date:'dd-MM-yyyy HH:MM'}}</p>
    <div class="buttonSpacer"></div>
    <share-popup-button [theme]="'default'" [include]="[
    'facebook',
    'twitter',
    'linkedin',
    'telegram',
    'whatsapp',
    'line',
    'sms',
    'email',
    'print',
    'copy'
  ]" [title]="(selectedVoucher)?.title" [description]="(selectedVoucher)?.subtitle"
            [image]="(selectedVoucher)?.url" [show]="5" [showIcon]="true" [showText]="true" [url]="currentURL">
        </share-popup-button>
</div>



<div class="voucherDescription">
    <p>{{(selectedVoucher)?.title}}</p>
    <p>{{(selectedVoucher)?.subtitle}}</p>
    <p>{{(selectedVoucher)?.description}}</p>
</div> -->



<p class="title">{{(selectedVoucher)?.brandName}}</p>

<div class="voucherContainer2">
    <div class="voucherImg" style="background-image: url({{(selectedVoucher)?.url}});"></div>
    <div class="voucherBanner">
        <p>{{(selectedVoucher)?.title}}</p>
        <div class="voucherInnerBanner">
            <p>Promo Code: <span class="higlighter">{{(selectedVoucher)?.promoCode}}</span> </p>

            <share-popup-button [theme]="'default'" [include]="[
           'facebook',
           'twitter',
           'linkedin',
           'telegram',
           'whatsapp',
           'line',
           'sms',
           'email',
           'print',
           'copy'
         ]" [title]="(selectedVoucher)?.title" [description]="(selectedVoucher)?.subtitle" [size]="-4"
                [image]="(selectedVoucher)?.url" [show]="5" [showIcon]="true" [showText]="true" [url]="currentURL">
            </share-popup-button>
        </div>
    </div>
</div>
<div class="voucherDescription2">
    <p class="voucherDescTitle">Voucher Description</p>
    <p class="voucherDescContent">{{(selectedVoucher)?.description}}</p>
    <p class="voucherDescExpiry">Expiry Date: <span class="higlighter">{{(selectedVoucher)?.endAt._seconds * 1000 |
            date:'dd-MM-yyyy HH:MM'}}</span></p>
</div>
<div class="voucherLimit">
    <p class="voucherRedemption">Redemption Limits</p>
    <div [ngSwitch]="(selectedVoucher)?.multiUse">
        <div class="voucherLimitText" *ngSwitchCase="'1'">- Each customer can only redeem <span
                class="higlighter">once</span></div>
        <div class="voucherLimitText" *ngSwitchCase="'2'">- Each customer can only redeem <span class="higlighter"> more
                then once</span></div>
    </div>
    <p class="voucherLimitText">- Limited to <span class="higlighter">{{(selectedVoucher)?.quantity}}</span> redemptions
    </p>
</div>
<div class="voucherTOU">
    <p class="voucherTOUTitle">Terms of Use</p>
    <div class="voucherTOUCOntent">
        <p>- Voucher must be presented to staff</p>
        <p>- Voucher is not valid in conjunction with other promotions or discounts. Please check with the merchant.</p>
        <p>- Valid only on outlets specified in description.</p>
        <p>- Voucher is not exchangeable for cash.</p>
        <p>- Voucher is limited while stocks last.</p>
        <p>- The merchant reserves the rights to change the terms and conditions with impunity.</p>
    </div>
</div>
<div class="brandInfo">
    <p class="brandInfoName">Brand Information</p>
    <div class="brandInfoContent">
        <div class="brandLogo" style="background-image: url({{(selectedVoucher)?.brandLogo}});"></div>
        <div class="brandName">
            <p>{{(selectedVoucher)?.brandName}}</p>
            <p>
                <nb-icon icon="star" status="info" [options]="{ animation: { type: 'zoom' } }"></nb-icon> {{(merchant)?.rating | number:'1.0-1'}}
            </p>
        </div>
        <div [ngSwitch]="sector">
            <div class="CTAbutton" *ngSwitchCase="'Service'" (click)='gotoURL((merchant)?.webURL)'>
                <button nbButton hero status="primary">
                    <nb-icon [options]="{ animation: { type: 'shake' } }" icon="globe"></nb-icon>Vist Website
                </button>
                <button nbButton hero status="success">
                    <nb-icon [options]="{ animation: { type: 'shake' } }" icon="heart-outline"></nb-icon>Service
                </button>

            </div>
            <div class="CTAbutton" *ngSwitchCase="'Retail'">
                <button nbButton hero status="primary" (click)='gotoURL((merchant)?.webURL)'>
                    <nb-icon [options]="{ animation: { type: 'shake' } }" icon="globe"></nb-icon>Vist Website
                </button>
                <button nbButton hero status="warning">
                    <nb-icon [options]="{ animation: { type: 'shake' } }" icon="heart-outline"></nb-icon>Retail
                </button>

            </div>

            <div class="CTAbutton" *ngSwitchCase="'F&B'">
                <button nbButton hero status="primary" (click)='gotoURL((merchant)?.webURL)'>
                    <nb-icon [options]="{ animation: { type: 'shake' } }" icon="globe"></nb-icon>Vist Website
                </button>
                <button nbButton hero status="danger">
                    <nb-icon [options]="{ animation: { type: 'shake' } }" icon="heart-outline"></nb-icon>F&B
                </button>
                </div>

                    <div class="CTAbutton" *ngSwitchCase="'Education'">
                        <button nbButton hero status="primary" (click)='gotoURL((merchant)?.webURL)'>
                            <nb-icon [options]="{ animation: { type: 'shake' } }" icon="globe"></nb-icon>Vist Website
                        </button>
                        <button nbButton hero status="success">
                            <nb-icon [options]="{ animation: { type: 'shake' } }" icon="heart-outline"></nb-icon>
                            Education
                        </button>

                    </div>

                    <div class="CTAbutton" *ngSwitchCase="'Publishing'">
                        <button nbButton hero status="primary" (click)='gotoURL((merchant)?.webURL)'>
                            <nb-icon [options]="{ animation: { type: 'shake' } }"  icon="globe"></nb-icon>Vist Website
                        </button>
                        <button nbButton hero status="success">
                            <nb-icon [options]="{ animation: { type: 'shake' } }" icon="heart-outline"></nb-icon>
                            Publishing
                        </button>

                    </div>
            


        </div>
    </div>
</div>