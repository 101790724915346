import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MerchantService } from '../merchant/merchant.service'
import { VoucherService } from '../voucher/voucher.service';

@Component({
  selector: 'app-merchant-detail',
  templateUrl: './merchant-detail.component.html',
  styleUrls: ['./merchant-detail.component.scss']
})
export class MerchantDetailComponent implements OnInit {

  constructor(private route: ActivatedRoute, private merchant: MerchantService , private voucher: VoucherService) { }
  selectedMerchant;
  id;
  vouchers;
  sector;
  outlets;
  currentURL;

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.merchant.getBrands().subscribe(brands => { 
      const tempid = this.id;
      const temp = brands.payload;
      let tempBrand;
      var filterObj = temp.filter(function(e) {
        if(e.id === tempid)
        tempBrand = e;
      });

      this.selectedMerchant = tempBrand;
      this.sector = this.selectedMerchant.sector;
      this.outlets = this.selectedMerchant.stores;
      console.log(this.selectedMerchant);
      this.currentURL= window.location.href;
    })

    this.voucher.getVouchers().subscribe(vouchers =>{
      const tempid = this.id;
      const temp = vouchers.payload;
      let tempVoucher = [];
      var filterObj = temp.filter(function(e) {
        if(e.brandId === tempid)
        tempVoucher.push(e);
      });

      this.vouchers = tempVoucher;
      console.log(this.vouchers);

    })

  }
  

}
