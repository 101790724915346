<p class="title">Merchant Details</p>

<div class="main">
    <nb-tabset>
        <nb-tab tabTitle="Profile">
            <div class="bannerPic" style="background-image: url({{ selectedMerchant?.url }});">
                <div class="logoPic" style="background-image:url({{ selectedMerchant?.logo }});"></div>
                <p class="spacer"></p>
            </div>
            <div [ngSwitch]="sector">
                <div class="CTAbutton" *ngSwitchCase="'Service'">
                    <button nbButton hero status="primary">
                        <nb-icon [options]="{ animation: { type: 'shake' } }" icon="globe"></nb-icon>Vist Website
                    </button>
                    <button nbButton hero status="success">
                        <nb-icon [options]="{ animation: { type: 'shake' } }" icon="heart-outline"></nb-icon>Service
                    </button>
                    <div class="buttonSpacer"></div>
                    <share-popup-button [theme]="'default'" [include]="[
                'facebook',
                'twitter',
                'linkedin',
                'telegram',
                'whatsapp',
                'line',
                'sms',
                'email',
                'print',
                'copy'
              ]" [title]="selectedMerchant?.name" [description]="selectedMerchant?.description"
                        [image]="selectedMerchant?.url" [show]="5" [showIcon]="true" [showText]="true" [url]="currentURL">
                    </share-popup-button>
                </div>
                <div class="CTAbutton" *ngSwitchCase="'Retail'">
                    <button nbButton hero status="primary">
                        <nb-icon [options]="{ animation: { type: 'shake' } }" icon="globe"></nb-icon>Vist Website
                    </button>
                    <button nbButton hero status="warning">
                        <nb-icon [options]="{ animation: { type: 'shake' } }" icon="heart-outline"></nb-icon>Retail
                    </button>
                    <div class="buttonSpacer"></div>
                    <share-popup-button [theme]="'default'" [include]="[
                'facebook',
                'twitter',
                'linkedin',
                'telegram',
                'whatsapp',
                'line',
                'sms',
                'email',
                'print',
                'copy'
              ]" [title]="selectedMerchant?.name" [description]="selectedMerchant?.description"
                        [image]="selectedMerchant?.url" [show]="5" [showIcon]="true" [showText]="true" [url]="currentURL">
                    </share-popup-button>
                </div>
    
                <div class="CTAbutton" *ngSwitchCase="'F&B'">
                    <button nbButton hero status="primary">
                        <nb-icon [options]="{ animation: { type: 'shake' } }" icon="globe"></nb-icon>Vist Website
                    </button>
                    <button nbButton hero status="danger">
                        <nb-icon [options]="{ animation: { type: 'shake' } }" icon="heart-outline"></nb-icon>F&B
                    </button>
                    <div class="buttonSpacer"></div>
                    <share-popup-button [theme]="'default'" [include]="[
                'facebook',
                'twitter',
                'linkedin',
                'telegram',
                'whatsapp',
                'line',
                'sms',
                'email',
                'print',
                'copy'
              ]" [title]="selectedMerchant?.name" [description]="selectedMerchant?.description"
                        [image]="selectedMerchant?.url" [show]="5" [showIcon]="true" [showText]="true" [url]="currentURL">
                    </share-popup-button>
                </div>
    
                <div class="CTAbutton" *ngSwitchCase="'Education'">
                    <button nbButton hero status="primary">
                        <nb-icon [options]="{ animation: { type: 'shake' } }" icon="globe"></nb-icon>Vist Website
                    </button>
                    <button nbButton hero status="success">
                        <nb-icon [options]="{ animation: { type: 'shake' } }" icon="heart-outline"></nb-icon>Education
                    </button>
                    <div class="buttonSpacer"></div>
                    <share-popup-button [theme]="'default'" [include]="[
                'facebook',
                'twitter',
                'linkedin',
                'telegram',
                'whatsapp',
                'line',
                'sms',
                'email',
                'print',
                'copy'
              ]" [title]="selectedMerchant?.name" [description]="selectedMerchant?.description"
                        [image]="selectedMerchant?.url" [show]="5" [showIcon]="true" [showText]="true" [url]="currentURL">
                    </share-popup-button>
                </div>
    
                <div class="CTAbutton" *ngSwitchCase="'Publishing'">
                    <button nbButton hero status="primary">
                        <nb-icon [options]="{ animation: { type: 'shake' } }" icon="globe"></nb-icon>Vist Website
                    </button>
                    <button nbButton hero status="success">
                        <nb-icon [options]="{ animation: { type: 'shake' } }" icon="heart-outline"></nb-icon>Publishing
                    </button>
                    <div class="buttonSpacer"></div>
                    <share-popup-button [theme]="'default'" [include]="[
                'facebook',
                'twitter',
                'linkedin',
                'telegram',
                'whatsapp',
                'line',
                'sms',
                'email',
                'print',
                'copy'
              ]" [title]="selectedMerchant?.name" [description]="selectedMerchant?.description"
                        [image]="selectedMerchant?.url" [show]="5" [showIcon]="true" [showText]="true" [url]="currentURL">
                    </share-popup-button>
                </div>
            </div>
            <p class="brandTitle">{{ selectedMerchant?.name }}</p>
            <p class="brandDesc">{{ selectedMerchant?.description }}</p>
        </nb-tab>
    
        <nb-tab tabTitle="Vouchers">
            <div class="menu-container">
                <div *ngFor="let voucher of vouchers">
                 
                    <div class="voucherContainer2" routerLink="/voucher/detail/{{ voucher.id }}" style = "background-image: url({{voucher.url}});">
                        <div class="voucherLogo2" style = "background-image: url({{voucher.brandLogo}});"></div>
                        <div class="line"></div>
                        <p class="voucherBrand">{{voucher.brandName}}</p>
                        <p class="voucherExpiry">Available till {{(voucher)?.endAt._seconds * 1000 | date:'dd-MM-yyyy'}}</p>
                        <div class="voucherBanner"><p class="voucherTitle2">{{voucher.title}}</p></div>
                    </div>
                

                </div>
            </div>
        </nb-tab>
    
        <nb-tab tabTitle="Outlets">
            <div class="outlet-container">
                <div *ngFor="let outlet of outlets">
                    <div class="outletContainer">
                        <div class="outletHero" style="background-image: url({{ outlet.url }});">
                            <div class="outletLogo" style="background-image:url({{ selectedMerchant?.logo }});"></div>
                            <p class="spacer"></p>
                        </div>
                        <p class="outletTitle">{{ outlet.name }}</p>
                        <p class="outletText">Outlet Location</p>
                        <p class="outletText">{{ outlet.area }}</p>
                        <p class="outletText">
                            {{ outlet.address }} Singapore{{ outlet.postalCode }}
                        </p>
                    </div>
                </div>
            </div>
        </nb-tab>
    </nb-tabset>
</div>
